import { Component, OnInit, HostListener, EventEmitter } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import * as FileSaver from 'file-saver';
import * as _ from 'lodash';
import { TeamnoteConfigService } from '../configs/teamnote-config.service';
import { ModuleKeyDefinition } from '../constants/module.constant';
import { UserContact } from '../models/user-contact';
import { LocalStorageManagerService } from '../utilities/local-storage/local-storage-manager.service';
import { LoggerService } from '../utilities/logger/logger.service';
import { TnDialogService } from '../utilities/tn-dialog/tn-dialog.service';
import { LanguageComponent } from './language/language.component';
import { OutOfOfficeComponent } from './out-of-office/out-of-office.component';
import { ProfileComponent } from './profile/profile.component';
import { AccountManagerService } from './services/account/account-manager.service';
import { ModuleManagerService } from './services/module/module-manager.service';
import { WebclientService } from './webclient.service';
import { DataManagerService } from './services/data/data-manager.service';
import { WebsiteTitleService } from '../utilities/website-title/website-title.service';
import { TeamNoteLocalStorageKeyConstants } from '../constants/local-storage-key.constant';
import { SocketService } from './services/socket/socket.service';
import { RouteParamService } from '../utilities/route-param/route-param.service';
import { PageUrlConstant } from '../constants/page-url.constant';
import { TnLoaderService } from '../utilities/tn-loader/tn-loader.service';
import { FileFactoryService } from '../utilities/file-factory/file-factory.service';
import { SideNavService } from '../utilities/tn-side-nav/side-nav.service';
import { TnNotificationService } from '../utilities/tn-notification/tn-notification.service';
import { TAndCService } from '../utilities/t-and-c/t-and-c.service';
import { ChatComponent } from './chat/chat.component';
import { Subscription } from 'rxjs';
import { UserContactService } from './services/data/user-contact/user-contact.service';
import { CookieService } from 'ngx-cookie';


import { ChatService } from './services/data/chat/chat.service';
import { UserGroupService } from './services/data/user-group/user-group.service';
import { MessagesService } from './services/data/messages/messages.service';
import { ChatMessageService } from './services/data/messages/chat-message/chat-message.service';
import { AccountService } from '../account/account.service';
import { NewsMessageService } from './services/data/messages/news-message/news-message.service';
import { Exercise, ExerciseService } from './exercise/exercise.service';
import { InfoMessageService } from './services/data/messages/info-message/info-message.service';
import { TeamnoteApiService } from '../api/teamnote-api.service';
import { MultiChatRoomService } from './chat/multi-chat-room.service';
export class SettingMenu {
  labelKey: string;
  action: Function;

  constructor(labelKey: string, action: Function) {
    this.labelKey = labelKey;
    this.action = action;
  }
}

@Component({
  selector: 'tn-webclient',
  templateUrl: './webclient.component.html',
  styleUrls: ['./webclient.component.scss']
})
export class WebclientComponent implements OnInit {
  loggedInUser: UserContact = null;
  avatarImageSrc: string = null;

  VERSION_NO: string;
  IS_ENABLE_DEBUG = false;
  IS_ENABLE_TNC = false;
  ENABLE_NOTIFICATION_CENTER = false;
  IS_UAT = false;
  BUILD_LABEL: string;
  NAV_LABEL: string;
  NAV_UAT_LABEL: string;
  ELECTRON_HIDE_LOGOUT_BTN = false;
  IS_ELECTRON = false;

  // Settings
  availableSettings = {
    outOfOffice: ModuleKeyDefinition.OUT_OF_OFFICE
  };
  settingMenus: SettingMenu[] = [];

  debugClickCount = 0;

  // dev mode
  devClickCount = 0;
  isDevMode = false;
  
  //checkCurrentLocation
  isWebapp: boolean = false;

  ENABLE_MULTI_ACCOUNTS = false;
  isInMultiChatRoomMode: Boolean = false;
  routerChildCompSub: Subscription
  availableLoginExercises: any = [];
  totalUnreadCount: number = 0;
  loggedInExercise: Exercise[] = [];
  activeEx: Exercise = null;

  isIframeMode: boolean = false;

  constructor(
    private _webclientService: WebclientService,
    private _accountManagerService: AccountManagerService,
    private _tnDialogService: TnDialogService,
    private _router: Router,
    private _loggerService: LoggerService,
    private _localStorageManagerService: LocalStorageManagerService,
    private _teamnoteConfigService: TeamnoteConfigService,
    private _moduleManagerService: ModuleManagerService,
    private _dataManagerService: DataManagerService,
    private _websiteTitleService: WebsiteTitleService,
    private _socketService: SocketService,
    private _routeParamService: RouteParamService,
    private _tnLoaderService: TnLoaderService,
    private _fileFactoryService: FileFactoryService,
    private _sideNavService: SideNavService,
    private _tnNotificationService: TnNotificationService,
    private _tAndCService: TAndCService,
    private _userContactService: UserContactService,

    private _chatService: ChatService,
    private _userGroupService: UserGroupService,
    private _messageService: MessagesService,
    private _chatMessageService: ChatMessageService,
    private _accountService: AccountService,
    private _newsMessageService: NewsMessageService,
    private _exerciseService: ExerciseService,
    private _infoMessageService: InfoMessageService,
    private _cookieService: CookieService,

    private _teamnoteApiService: TeamnoteApiService,
    private _multiChatRoomService: MultiChatRoomService,
  ) {
    this._teamnoteConfigService.config$.subscribe((config) => {
      this.VERSION_NO = config.VERSION.VERSION;
      this.IS_ENABLE_DEBUG = config.GENERAL.IS_ENABLE_DEBUG;
      this.ENABLE_NOTIFICATION_CENTER = config.NOTIFICATION.ENABLE_NOTIFICATION_CENTER;
      this.IS_ENABLE_TNC = config.GENERAL.IS_ENABLE_TNC;
      this.IS_UAT = config.GENERAL.IS_UAT;
      this.BUILD_LABEL = config.GENERAL.BUILD_LABEL;
      this.NAV_LABEL = config.GENERAL.NAV_LABEL;
      this.NAV_UAT_LABEL = config.GENERAL.NAV_UAT_LABEL;
      this.ELECTRON_HIDE_LOGOUT_BTN = config.GENERAL.ELECTRON_HIDE_LOGOUT_BTN;
      this.ENABLE_MULTI_ACCOUNTS = config.WEBCLIENT.GENERAL.ENABLE_MULTI_ACCOUNTS;
    });

    this._loggerService.debug('Subscribing to unread count update: to update unread count & website title');
    this._sideNavService.unreadCount$.subscribe(count => {
      if (!this._accountManagerService.isEnableMultiAccountLogin()) {
        this._websiteTitleService.setWebsiteTitle(count);
      }
    });

    this._sideNavService.allUnreadCountByAccount$.subscribe(count => {
      if (this._accountManagerService.isEnableMultiAccountLogin()) {
        if (!_.isEmpty(this.availableLoginExercises)) {
          this.updateUnreadCountForLoggedInAccounts()
        }
      }
    });

    this._router.events.subscribe((e) => {
      // console.warn(e);
      if (e instanceof NavigationEnd) {
        if (e.urlAfterRedirects.lastIndexOf('/') !== 0) {
          // trip query params
          const routeParts = e.urlAfterRedirects.split('?');
          this._localStorageManagerService.setCookiesByKey(TeamNoteLocalStorageKeyConstants.USER_CONFIG_COOKIES.ROUTE, routeParts[0]);
        }
      }
    });

    this._sideNavService.currentActiveItemKey$.subscribe(count => {
      if (this._sideNavService.currentActiveItemKey === 'iframe_dashboard') {
        this.isIframeMode = true;
      } else {
        this.isIframeMode = false;
      }
    });
  }

  ngOnInit() {
    this._tnLoaderService.hideSpinner();
    this._loggerService.debug('Webclient ngOnInit - Calling function: webclientService.onLoginSuccess...');
    this._webclientService.onLoginSuccess();

    this._loggerService.debug('Subscribing to selfContactPresence: to update current logged in user\'s info');
    this._accountManagerService.selfContactPresence$.subscribe(user => {
      this._loggerService.debug('Updating loggedInUser');
      this.loggedInUser = user;
      this.parseUser();

      if (!this._accountManagerService.isEnableMultiAccountLogin()) {
        return;
      }
      // for multiple account
      this.prepareParseLoggedInAccounts();
    });

    // if (this._accountManagerService.isEnableMultiAccountLogin()) {
      this._exerciseService.exercises$.subscribe(exs => {
        this.prepareParseLoggedInAccounts();
      });
    // }
    this._exerciseService.activeExercise$.subscribe(activeEx => {
      this.activeEx = activeEx;
    });
    

    this.setUpSettingMenus();
    this.restorePreviousSession();

    this._loggerService.debug('Setting login time');
    this._localStorageManagerService.setSessionStorageByKey(TeamNoteLocalStorageKeyConstants.SESSION.LOGIN_TIME, new Date().getTime());

    // @ts-ignore
    this.IS_ELECTRON = !!window.tnElectronApi;
    this.checkCurrentPort();
  }

  parseUser(): void {
    if (!this.loggedInUser) {
      return;
    }
    if (this.loggedInUser.pic && this.loggedInUser.pic !== 'None') {
      this._loggerService.debug('Setting logged in user\'s avatar iamge...');
      this._fileFactoryService.getFileByAttachmentId(
        this.loggedInUser.pic,
        (imageUrl) => {
          this.avatarImageSrc = imageUrl;
        },
        (err) => {},
        false,
        false,
        true
      );
    } else {
      this.avatarImageSrc = null;
    }
  }

  test() {
    // console.log('_newsMessageService', this._newsMessageService);
    // console.log('currentRouteParam', this._sideNavService.currentActiveItemKey);
    // console.log('_accountService', this._accountService)
    console.log('_accountManagerService allLoggedInAccountRes', this._accountManagerService.allLoggedInAccountRes)
    // console.log('all websocket connection instance: ', this._socketService._socketConnections);
    // console.log('all account reconnectionTimerByAccount: ', this._webclientService.reconnectionTimerByAccount);
    // console.log('_socketConnections:', this._socketService);
    // console.log('callbacksGroupByUserId', this._socketService.callbacksGroupByUserId);
    // console.log('rosterGroupByAccountUserId', this._dataManagerService.rosterGroupByAccountUserId);
    // console.log(`inserted UserContactPresences`, this._userContactService._userContactPresences);
    // console.log(`inserted UserGroupsCollection`, this._userGroupService._userGroupsCollection);
    // console.log(`insert Chat _chatsCollection`, this._chatService._chatsObjCollection);
    // console.log(`All chat from active account`, this._chatService.chats);

    // console.log('all messages by account', this._messageService._messagesObjCollection);
    // console.log('!!! unreadMessages !!!', this._chatMessageService.unreadMessages);
    // console.log('!!! _chatMessagesObjCollection unreadMessages !!!', this._chatMessageService._chatMessagesObjCollection);
    console.log('_exerciseService exercises', this._exerciseService.exercises);
    console.log('_exerciseService activeExercise', this._exerciseService.activeExercise);
    console.log('_infoMessageService', this._infoMessageService.messageAnnotations);

    // this._webclientService.onLogoutWebClient(true)
    
    // console.log('_multiChatRoomService multiChatRoomsPanels', this._multiChatRoomService.multiChatRoomsPanels);
    // console.log(this.loggedInUser);

    // console.log('TeamNoteLocalStorageKeyConstants.SESSION.PASSWORD', this._localStorageManagerService.getLocalStorageByKey(TeamNoteLocalStorageKeyConstants.SESSION.PASSWORD));

    // this.testRelogin();

    // console.log(this._tnNotificationService.showCustomInfoByTranslateKey("WEBCLIENT.CHAT.CHAT_GROUP.ERROR_MSG.UPDATE_COMMANDER_VIEW_FAIL"));
  }

  get enableExerciseSelection() {
    // return this._moduleManagerService.checkIfModuleExists(ModuleKeyDefinition.MULTIPLE_DOMAIN_LOGIN) && this._exerciseService.exercises.length > 0;
    return this._accountService.checkIfRootUserModuleExists(ModuleKeyDefinition.MULTIPLE_DOMAIN_LOGIN) && this._exerciseService.exercises.length > 0;
  }

  async prepareParseLoggedInAccounts() {
    // this.availableLoginExercises = await this.parseLoggedInAccounts()
    let parsedLoggedInUsers = await this.parseLoggedInAccounts()

    this.availableLoginExercises = _.map(this._exerciseService.exercises, (ex) => {
      let parsedExercise = {
        ...ex
      }

      if (ex.user_id) {
        let user = _.find(parsedLoggedInUsers, { user_id: ex.user_id });
        if (user) {
          parsedExercise.user = { ...user };
        }
      }

      return parsedExercise;
    })

    this.availableLoginExercises = _.orderBy(this.availableLoginExercises, [(ex) => ex.user_id === this._exerciseService.activeExercise?.user_id], ['desc']);

    this.updateUnreadCountForLoggedInAccounts();
  }

  parseLoggedInAccounts(): Promise<any> {
    let parsedUsers = _.map(this._accountManagerService.allLoggedInAccountRes, (acc) => {
      let userInfo: any = {};

      userInfo.name = acc.user.name;
      userInfo.user_id = acc.user.user_id;
      userInfo.pic = acc.user.pic;
      userInfo.unreadCount = 0;

      const sc = this._accountManagerService.getSelfContactPresenceByAccountUserId(acc.user.user_id);

      // use target user's updated UserContact data
      if (sc) {
        userInfo.name = sc.name;
        userInfo.user_id = sc.user_id;
        userInfo.pic = sc.pic;
      } 

      return new Promise((resolve, reject) => {
        if (userInfo.pic && userInfo.pic !== 'None') {
          // this._loggerService.debug("Setting logged in user's avatar iamge...")
          this._fileFactoryService.getFileByAttachmentId(
            userInfo.pic,
            (imageUrl) => {
              userInfo.avatarImageSrc = imageUrl;
              resolve(userInfo)
            },
            err => {
              console.error(err); 
              reject(err); 
            },
            false,
            false,
            true
          )
        } else {
          resolve(userInfo)
        }
      })
    })

    return Promise.all(parsedUsers)
  }

  updateUnreadCountForLoggedInAccounts(): void {
    let count = 0;
    // _.each(this.availableLoginExercises, (acc) => {
    //   let chatMessageObj = this._chatMessageService.getChatMessagesObjCollectionByAccountUserId(acc.user_id)
    //   acc.unreadCount = _.size(chatMessageObj.unreadMessages)

    //   count += acc.unreadCount;
    // })

    _.each(this.availableLoginExercises, (ex) => {
      if (ex.user) {
        let chatMessageObj = this._chatMessageService.getChatMessagesObjCollectionByAccountUserId(ex.user_id)

        if (chatMessageObj) {
          ex.user.unreadCount = _.size(chatMessageObj.unreadMessages)

          count += ex.user.unreadCount;
        }
      }
    })

    this.totalUnreadCount = count;
    this._websiteTitleService.setWebsiteTitle(count);
  }

  switchToTargetExercise(ex: Exercise): void {
    if (ex.is_loading) {
      this._tnNotificationService.showCustomWarningByTranslateKey('WEBCLIENT.EXERCISE.EXERCISE_INIT_WARNING');
      return;
    }

    if (ex.user_id === this.loggedInUser?.user_id) {
      return;
    }

    this._exerciseService.updateActiveExercise(this._exerciseService.getExerciseByCompanyDomain(ex.company_domain, ex.user_name));
    console.log('the exercise to be landed', ex);
    this._loggerService.debug(`The exercise to be landed ${JSON.stringify(ex)}`);
    const loggedInExerciseUser = this._accountManagerService.getLoggedInAccounResByUserId(ex.user_id);
    console.log('loggedInExerciseUser', loggedInExerciseUser);
    if (loggedInExerciseUser) {
      /* target exercise has logged in already, switch directly */
      console.log('target exercise has logged in already, switch directly');
      this._loggerService.debug(`Target exercise has logged in already, switch directly`);
      this._dataManagerService.switchToTargetAccountByUserId(loggedInExerciseUser.user.user_id || ex.user_id);
    } else {
      /* target exercise has not logged in, start exercise login flow */
      this._loggerService.debug(`Target exercise (domain: ${ex.company_domain}) has not logged in, start exercise login flow`);

      console.log('target exercise has not logged in, start exercise login flow');
      const exercise = this._exerciseService.getExerciseByCompanyDomain(ex.company_domain, ex.user_name);
      this._exerciseService.loginTargetExercise(exercise);
    }
  }

  setUpSettingMenus(): void {
    _.each(this.availableSettings, (s) => {
      if (this._moduleManagerService.checkIfModuleExists(s)) {
        switch (s) {
          case ModuleKeyDefinition.OUT_OF_OFFICE:
            this.settingMenus.push(new SettingMenu('WEBCLIENT.MENU.SETTINGS.OUT_OF_OFFICE.TITLE', () => {this.openOutOfOfficeModal(); }));
            break;
        }
      }
    });
  }

  restorePreviousSession(): void {
    const previousRoute = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.USER_CONFIG_COOKIES.ROUTE);
    this._localStorageManagerService.removeCookiesByKey(TeamNoteLocalStorageKeyConstants.USER_CONFIG_COOKIES.ROUTE);

    if (!previousRoute || previousRoute === '/' || previousRoute.indexOf('login') !== -1) {
      this.goToWebclientDefaultPage();
      return;
    }

    this._dataManagerService.prevSessionRoute = previousRoute;

    const metaData = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.USER_CONFIG_COOKIES.ROUTE_META_DATA);
    let prevQueryString = metaData;
    try {
      prevQueryString = JSON.parse(metaData);
      this._routeParamService.setRouteParam(prevQueryString);
    } catch (e) {}

    this._loggerService.log('Restore previous session: ' + previousRoute);
    this._router.navigateByUrl(previousRoute);
  }

  goToWebclientDefaultPage(): void {
    const defaultPage = this._teamnoteConfigService.config.WEBCLIENT.GENERAL.WEBCLIENT_DEFAULT_PAGE;
    this._router.navigateByUrl('/' + [PageUrlConstant.WEBCLIENT.BASE, defaultPage].join('/'));
  }

  // Profile Modal
  openMyProfileModal(): void {
    this._loggerService.log('Clicked \'Profile\', opening profile dialog...');
    const dialogRef = this._tnDialogService.openTnDialog(ProfileComponent);
  }

  // Language Modal
  openLanguageSelectModal(): void {
    this._loggerService.log('Clicked \'Language\', opening language selection dialog...');
    const languageDialogRef = this._tnDialogService.openTnDialog(LanguageComponent);
  }

  // T&C
  openTAndCModal(): void {
    this._tAndCService.openTAndCDialog(false);
  }

  // Out of office
  openOutOfOfficeModal(): void {
    this._loggerService.log('Clicked \'Language\', opening language selection dialog...');
    const outOfOfficeDialogRef = this._tnDialogService.openTnDialog(OutOfOfficeComponent);
  }

  @HostListener('window:beforeunload') onWindowBeforeUnload() {
    this._loggerService.debug('Webclient is being unload');
    if (this._accountManagerService.userId) {
      this._loggerService.debug('Setting disconnet time...');
      this._socketService.setDisconnectTime();
    }
  }

  manualLogout(): void {
    this._loggerService.log('Clicked \'Logout\', calling webclientService.onLogoutWebclient(false)...');
    this._webclientService.onLogoutWebClient(false);
    this.loggedInUser = null;
  }

  simulateDisconnectMultiAccountSockets(): void {
    this._loggerService.log('Simulating multi account socket disconnection...');
    this._webclientService.disconnectMultiAccountSockets();
  }
  simulateDisconnect(): void {
    this._loggerService.log('Simulating socket disconnection...');
    this._webclientService.simulateDisconnect();
  }
  simulateReconnect(): void {
    this._loggerService.log('Simulating socket reconnecting...');
    this._webclientService.simulateReconnect();
  }

  clearDebugClickCount(): void {
    if (this.debugClickCount > 0) {
      this.debugClickCount = 0;
    }
  }
  onDebugClick(): void {
    this.debugClickCount++;
    if (this.debugClickCount === 10) {
      this.tnDebugger();
      this.clearDebugClickCount();
    }

    setTimeout(() => this.clearDebugClickCount(), 2000);
  }

  tnDebugger(): void {
    const db = this._webclientService.tnDebugger();
    const cookies = this._localStorageManagerService.getAllCookies();
    const logs = this._loggerService.logs;

    const allLogs = {
      db: db,
      cookies: cookies,
      logs: logs
    };

    const file = new Blob([
      JSON.stringify(allLogs, null, 2)
    ], {type: 'text/plain'});
    const fileName = window.location.host + '-' + _.now() + '.log';
    FileSaver.saveAs(file, fileName);
  }

  clearDevClickCount(): void {
    if (this.devClickCount > 0) {
      this.devClickCount = 0;
    }
  }
  onDevToggleClick(): void {
    if (this.isDevMode || !this.IS_ENABLE_DEBUG) {
      return;
    }

    this.devClickCount++;
    if (this.devClickCount === 10) {
      this.enableDevMode();
      this.clearDevClickCount();
    }

    setTimeout(() => this.clearDevClickCount(), 2000);
  }

  enableDevMode(): void {
    this._tnNotificationService.showConfirm(
      'Enable DEV mode?',
      () => {
        this.isDevMode = true;
        this._webclientService.enableDevMode();
      },
      () => {

      }
    );
  }

  checkCurrentPort(): void {
    if (window.location.hash.includes("webapp")){
      this.isWebapp = true;
    }
  }
  
  onToggleMultiChatRoomMode(isMultiChatMode: boolean): void {
    this.isInMultiChatRoomMode = isMultiChatMode;
  }

  subscribeToEmmiter(componentRef) {
    if (!(componentRef instanceof ChatComponent)) {
      return
    }

    const child: ChatComponent = componentRef
    child.chatroomModeToggleEvent.subscribe(modeStatus => {
      this.onToggleMultiChatRoomMode(modeStatus)
    })
  }

  unsubscribeEmmiter() {
    if (this.routerChildCompSub) {
      this.routerChildCompSub.unsubscribe()
    }
  }
}
