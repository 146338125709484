<div class="tn-webclient" [class.web-app]="isWebapp">
  <div class="tn-header theme-color-primary" *ngIf="!isWebapp">
    <div class="nav-logo-exercise-container">
      <div class="nav-logo-wrapper">
        <div class="tn-nav-logo nav-logo" (click)="onDevToggleClick()"></div>
        <div class="topnav-label" *ngIf="NAV_LABEL">
          {{NAV_LABEL}}
        </div>
        <div class="topnav-label red" *ngIf="NAV_UAT_LABEL">
          {{NAV_UAT_LABEL}}
        </div>
        <!-- <div class="build-label" *ngIf="BUILD_LABEL">
          {{BUILD_LABEL}}
        </div> -->
      </div>
      <div class="active-exercise-info" *ngIf="activeEx">
        <!-- {{'WEBCLIENT.EXERCISE.EXERCISE_LANGDING' | translate}}  -->
        {{activeEx?.company_name}}
      </div>
    </div>

    <div class="user-info">
      <!-- <button class="btn tn-button-primary btn-block" (click)="test()" style="width: 50px;">test</button> -->
      <div class="logged-in-user" *ngIf="!enableExerciseSelection || enableExerciseSelection && activeEx">
        <div class="avatar-wrapper">
          <div class="avatar default-contact-avatar" [ngStyle]="{'background-image': avatarImageSrc}"></div>
        </div>
        <div class="name" *ngIf="loggedInUser">
          {{loggedInUser.name}}
        </div>
        <div class="btn-group tn-dropdown-group logged-in-accounts" dropdown placement="bottom right" *ngIf="enableExerciseSelection">
          <button dropdownToggle type="button" class="dropdown-toggle tn-dropdown-button">
            <i class="fa fa-angle-down fa-fw"></i>
          </button>
          <div class="total-unread-count" *ngIf="totalUnreadCount > 0">
            <!-- {{ totalUnreadCount }} -->
          </div>
          <ul *dropdownMenu class="dropdown-menu dropdown-menu-right exercise-dropdown" role="menu">
            <!-- [ngClass]="{'active-background': ex.user?.user_id === loggedInUser.user_id, 'clickable': ex.user?.user_id !== loggedInUser.user_id}" -->
            <li
              role="accountitem"
              class="tn-dropdown-container"
              [ngClass]="{'clickable': !ex.user || ex.user?.user_id !== loggedInUser?.user_id}"
              *ngFor="let ex of availableLoginExercises"
              (click)="switchToTargetExercise(ex)"
            >
              <ng-container *ngIf="ex.user; else exerciseLabel">
                <div class="exercise-container">
                  <div
                    class="text-color-primary exercise-info"
                    [ngClass]="{'active-background': ex.user.user_id === loggedInUser?.user_id}"
                  >
                    <span>{{ ex.company_name }}</span>
                    <!-- <span class="unread-count unread-bubble" *ngIf="ex.user.unreadCount > 0">
                      {{ ex.user.unreadCount }}
                    </span> -->
                  </div>
                  <div class="ex-user-info">
                    <div class="avatar-wrapper">
                      <div
                        class="avatar default-contact-avatar"
                        [ngStyle]="{ 'background-image': ex.user.avatarImageSrc }"
                      ></div>
                    </div>
                    <div class="name-black">
                      {{ ex.user.name }}
                    </div>
                    <div class="ex-user-loading" *ngIf="ex.is_loading">
                      <i class="fa fa-spinner fa-pulse loading-icon fa-fw"></i>
                    </div>
                    <span class="unread-count unread-bubble" *ngIf="!ex.is_loading && ex.user.unreadCount > 0">
                      {{ ex.user.unreadCount }}
                    </span>
                  </div>
                </div>
              </ng-container>
              <ng-template #exerciseLabel>
                <div
                  class="exercise-label clickable"
                  [ngClass]="{
                    'text-color-primary': ex.user && ex.user_id,
                    'unlogged-in-color': !ex.user || !ex.user_id
                  }"
                >
                  {{ ex.company_name }}
                </div>
              </ng-template>
            </li>
            <!-- <li class="divider dropdown-divider"></li> -->
          </ul>
        </div>
      </div>

      <tn-notification-center
        *ngIf="ENABLE_NOTIFICATION_CENTER">
      </tn-notification-center>

      <div class="btn-group tn-dropdown-group" dropdown placement="bottom right">
        <button dropdownToggle type="button" class="dropdown-toggle tn-dropdown-button">
          <i class="fa fa-angle-down fa-fw"></i>
        </button>
        <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
          <li role="menuitem">
            <div class="tn-dropdown-item" (click)="openMyProfileModal()">
              {{'WEBCLIENT.MENU.MY_PROFILE' | translate}}
            </div>
          </li>
          <li class="divider dropdown-divider"></li>
          <li role="menuitem">
            <div class="tn-dropdown-item" (click)="openLanguageSelectModal()">
              {{'GENERAL.LANGUAGE' | translate}}
            </div>
          </li>
          <li role="menuitem" *ngIf="IS_ENABLE_TNC">
            <div class="tn-dropdown-item" (click)="openTAndCModal()">
              {{'GENERAL.TERMS_AND_CONDITIONS' | translate}}
            </div>
          </li>
          <li role="menuitem" *ngFor="let setting of settingMenus">
            <div class="tn-dropdown-item" (click)="setting.action()">
              {{setting.labelKey | translate}}
            </div>
          </li>
          <li class="divider dropdown-divider"></li>
          <li role="menuitem" *ngIf="!ELECTRON_HIDE_LOGOUT_BTN || !IS_ELECTRON">
            <div class="tn-dropdown-item" (click)="manualLogout()">
              {{'GENERAL.LOGOUT' | translate}}
            </div>
          </li>
          <li role="menuitem" *ngIf="IS_ENABLE_DEBUG">
            <div class="tn-dropdown-item" (click)="tnDebugger()">
              Download Log
            </div>
          </li>
          <ng-container *ngIf="isDevMode">
            <li role="menuitem" *ngIf="ENABLE_MULTI_ACCOUNTS">
              <div class="tn-dropdown-item" (click)="simulateDisconnectMultiAccountSockets()">
                [DEV] - Disconnect All Account Sockets
              </div>
            </li>
            <ng-container *ngIf="!ENABLE_MULTI_ACCOUNTS">
              <li role="menuitem">
                <div class="tn-dropdown-item" (click)="simulateDisconnect()">
                  [DEV] - Disconnect Socket
                </div>
              </li>
              <li role="menuitem">
                <div class="tn-dropdown-item" (click)="simulateReconnect()">
                  [DEV] - Reconnect Socket
                </div>
              </li>
            </ng-container>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>

  <div class="tn-content-body">
    <div class="tn-sidebar" [ngClass]="{ 'multi-chatroom-mode': isInMultiChatRoomMode, 'iframe-mode': isIframeMode }" *ngIf="!isWebapp">
      <tn-side-nav></tn-side-nav>
    </div>

    <div class="tn-content-container" [ngClass]="{ 'multi-chatroom-mode-flex': isInMultiChatRoomMode || isIframeMode }">
      <router-outlet (activate)="subscribeToEmmiter($event)" (deactivate)="unsubscribeEmmiter()"></router-outlet>
    </div>
  </div>

  <div class="tn-footer" *ngIf="!isWebapp">
    <div class="footer-item copyright">
      {{'GENERAL.COPYRIGHT_MSG' | translate}}
    </div>
    <div class="footer-item version-number">
      <span (click)="onDebugClick()">{{VERSION_NO}}</span>
    </div>
  </div>
</div>
